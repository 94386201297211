export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const BASE_URL = SERVER_URL + "/api"

export const requestConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true,
    credentials: 'include'
}

export function formatCashValue(amount) {
    let a = Number(amount).toFixed(2);
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}